import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import {ErrorHandler, NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';


import {
    MatButtonModule,
    MatToolbarModule,
    MatCardModule,
    MatInputModule,
    MatDialogModule,
    MatTableModule,
    MatMenuModule,
    MatIconModule,
    MatProgressSpinnerModule,
} from '@angular/material';


import {AppRoutingModule} from './app.routing';
import {ComponentsModule} from './components/components.module';

import {AppComponent} from './app.component';
import {
    AgmCoreModule
} from '@agm/core';
import {AdminLayoutComponent} from './layouts/admin-layout/admin-layout.component';
import {TradeStockComponent} from './trades/trade-stock/trade-stock.component';
import {AddTradeStockComponent} from './trades/add-trade-stock/add-trade-stock.component';
import {ErrorsService} from './errors.service';
import {HttpClientModule} from '@angular/common/http';
import {LoginComponent} from './login/login.component';
import { ToastrModule } from 'ngx-toastr';

@NgModule({
    imports: [
        BrowserAnimationsModule,
        BrowserModule,
        MatButtonModule,
        MatToolbarModule,
        MatButtonModule,
        MatCardModule,
        MatInputModule,
        MatDialogModule,
        MatTableModule,
        MatMenuModule,
        MatIconModule,
        MatProgressSpinnerModule,
        FormsModule,
        ReactiveFormsModule,
        HttpClientModule,
        ComponentsModule,
        RouterModule,
        AppRoutingModule,
        AgmCoreModule.forRoot({
            apiKey: 'YOUR_GOOGLE_MAPS_API_KEY'
        }),
        ToastrModule.forRoot(),
    ],
    declarations: [
        AppComponent,
        AdminLayoutComponent,
        TradeStockComponent,
        AddTradeStockComponent,
        LoginComponent,


    ],
    providers: [
        {provide: ErrorHandler, useClass: ErrorsService},
        // {provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true},
        ],
        bootstrap: [AppComponent]
})
export class AppModule {
}
