import {Injectable, ErrorHandler} from '@angular/core';
import {HttpErrorResponse} from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';


@Injectable({
    providedIn: 'root'
})
export class ErrorsService implements ErrorHandler {
    constructor(private toastr: ToastrService) {}

    handleError(error: any) {
        if (error instanceof HttpErrorResponse) {
            this.toastr.error(error.error.message)
        } else {
            console.log(error)
            console.log('Other error: ' + error.message)
        }
    }
}

