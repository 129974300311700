export class TradingStock {
    id: number;
    name: string;
    remainingUnits: string;
    amount: string;
    tradeId: number;
    volume: number;
    displayname: string;
    fundingCost: string;
    bankCharges: string;
    securityDate: Date;

    constructor() {

    }
}
