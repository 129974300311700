import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {LoggingService} from './logging.service';
import {Observable} from 'rxjs';
import {Trade} from '../trades/trade.model';
import {account_routes, trade_routes} from '../helpers/env-endpoints';

import {RoleModel} from '../models/role.model';
import {User} from '../models/user.model';

@Injectable({
    providedIn: 'root'
})
export class UserService {

    private httpOptions = {
        headers: new HttpHeaders({
            'Content-Type': 'application/json'
        })
    };

    constructor(private http: HttpClient, private loggingService: LoggingService) {

    }

    updateProfile(obj: any): Observable<any> {

        return this.http.post(account_routes.UPDATE_PROFILE, obj, this.httpOptions);
    }

    getRoles(): Observable<any[]> {
        return this.http.get<any[]>(account_routes.ROLES);
    }

    getAllUsers(): Observable<any[]> {
        return this.http.get<any[]>(account_routes.GET_ALL_PROFILES);
    }

    userLogin(obj: any): Observable<any> {

        return this.http.post(account_routes.LOGIN, obj, this.httpOptions);
    }

    getUserById(id: number): Observable<any> {
        return this.http.get<User>(account_routes.GET_PROFILE_BY_ID + '/' + id);
    }

    userRegister(obj: any): Observable<any> {

        return this.http.post(account_routes.REGISTER, obj, this.httpOptions);
    }
}
